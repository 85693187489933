import React from "react"
import { ActivityBox, CopyrightBox, DiscussBox, ImagineBox } from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { YouTubeVideo } from '../../../components/video'
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const StainedGlass = () => (
  <Layout title="Stained Glass">
    <SmallSectionHeader
      color={theme.colors.yellow.dark}
      image={headerImage}
      to="/how-did-they-build-that"
    >
      How did they build that?
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/how-did-they-build-that', name: 'How did they build that?' },
      { name: 'Stained Glass' }
    ]} />
    <Page>
      <h1>Stained Glass in Canterbury Cathedral</h1>
      <div className="page-left">
        <p>
          Many of the windows of Canterbury Cathedral contain stained glass, in fact there is around 1200m<sup>2</sup> of stained glass in the Cathedral as a whole. The oldest glass in the building dates from the twelfth century and is over 840 years old. These particular windows show the genealogies (family trees) of biblical figures.
        </p>
        <p>
          Over the years, many of the original windows have been destroyed. Some were lost during the reformation and many more during the English Civil war.
        </p>
        <p>
          In 1942, during World War II, Canterbury suffered heavy bombing. Much of the remaining medieval glass in The Cathedral only survived because it had been removed and stored in crates for safe keeping at the beginning of the war.
        </p>
        <p>
          The windows of Canterbury Cathedral continue to bring colour and light to the building, helping to create a sense of awe and wonder. Even today, new windows continue to be created for the Cathedral and a team of conservators (someone responsible for the care and repair of objects or buildings of special interest) work hard to look after the beautiful glass and ensure it will still be here for people to enjoy in the future.
        </p>
      </div>
      <div className="page-right">
        <Figure
          caption="Great South Window in the Canterbury Cathedral nave."
        >
          <StaticImage src="./great-south-window.jpg" alt="South Window in the Cathedral" />
        </Figure>
        <ImagineBox title="Imagine" className="page-right">
          Imagine you are a medieval pilgrim coming to The Cathedral for the first time – how does the stained glass make you feel?
        </ImagineBox>
      </div>

      <DiscussBox>What is a stained glass window?</DiscussBox>

      <p className="page-left">
        Stained glass windows are expensive and very time consuming to produce. In medieval times they helped to show that the Cathedral was a wealthy and important building.
      </p>
      <Figure
        className="page-right row-2"
      >
        <StaticImage
          src="./window.jpg"
          alt="Stained Glass Window"
        />
      </Figure>
      <p className="page-left">
        Some people think that in the past stained glass helped to tell the bible stories when ordinary people could not read the bible for themselves. In Canterbury Cathedral, some of the Stained glass windows honour important individuals such as Kings, Queens and Saints. There is also a very famous series of windows that depict some of the miracles (an extraordinary event taken as a sign of the power of God) of Saint Thomas Becket.
      </p>

      <DiscussBox>Why do churches and cathedrals have stained glass windows?</DiscussBox>

      <h2>Step-by-step</h2>
      <div className="page-left">
        <p>
          The way we make stained glass windows is almost the same as it was in medieval times.
        </p>
        <ol>
          <li>
            A small design is drawn to be approved by the Cathedral. This is called a ‘vidimus’.
          </li>
          <li>
            A template of the window opening is made.
          </li>
          <li>
            A scale design called a ‘cartoon’ is drawn. In the medieval period when paper was expensive this was drawn on a whitewashed table.
          </li>
          <li>
            Sheets of glass are laid over the cartoon and cut to size. In the medieval period a rough shape was cut with a hot iron, the glass was then shaped further using a tool called a grozing iron which nibbled the edge of the glass. Today glaziers use a steel wheel cutter.
          </li>
          <li>
            The pieces of cut glass are laid over the cartoon and details of the design are painted onto the glass tracing from template below.
          </li>
          <li>
            The painted and stained glass is heated to a high temperature to make the paint permanent. This is called firing.
          </li>
          <li>
            When the glass is cooled it is fitted together using strips of lead. The lead is a ‘H’ shape and this helps to hold the glass, it is soft and can be easily bent around the shape of each piece of glass.
          </li>
          <li>
            As each piece of glass is fitted it is held in place by holding nails. Once the whole window is assembled the joints are soldered together.
          </li>
        </ol>
      </div>
      <Figure
        className="page-right"
      >
      <StaticImage
        src="./artist.png"
        alt="Artist"
      />
      </Figure>

      <YouTubeVideo id="RsoJR0zWv_U" />

      <h2>Activities</h2>
      <ActivityBox
        title="How Did They Build That activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/HowDidTheyBuildThat.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘How did they build that ’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Architecture", to: "/how-did-they-build-that/building-the-cathedral" },
          { name: "Stone", to: "/how-did-they-build-that/stone" },
          { name: "How is glass made?", to: "/how-did-they-build-that/glass" },
        ]}
      />

      <hr />

      <CopyrightBox />
    </Page>
  </Layout>
)

export default StainedGlass;